import { useDispatch, useSelector } from "react-redux";
import "./LeaderBoard.css";
import { setModalToShow } from "../../../reduxStorage/modal/modal";
import Position from "./Position";
import ReactPaginate from "react-paginate";
import { useState } from "react";
import { RootReducer } from "../../../reduxStorage/configureStore";
import { TopUser } from "../../../reduxStorage/leaderboard/leaderboard";

const itemsPerPage = 5;
const LeaderBoard = () => {
    const dispatch = useDispatch();
    const items = useSelector((storage: RootReducer) => storage.leaderboard.list);
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.top.slice(itemOffset, endOffset).concat([items.user])
    const pageCount = Math.ceil((items.top.length) / itemsPerPage);

    function Items({items}: {items: TopUser[]}) {
        return (
            <div style={{height: "100%"}}>
                {items && 
                items.map((item: TopUser, inx: number) => {
                    if(items.length - 1 <= inx) {
                        return {
                            isMe: true,
                            ...item
                        }
                    } else {
                        return {
                            isMe: false,
                            ...item
                        }
                    }
                }).sort((a, b) => a.place - b.place).map((it: any) => {
                    return <Position data={it} />
                })}
            </div>
        )
    }

    const handlePageClick = (event: any) => {
        if(items.top.length <= 0) return;
        const newOffset = (event.selected * itemsPerPage) % items.top.length;
        setItemOffset(newOffset);
    };
    return (
        <div className="leaderBoardCont">
            <div className="crossLeader">
                <img src="/svg/cross.svg" onClick={() => {
                    dispatch(setModalToShow("none"))
                }}></img>
            </div>
            <div className="bgCup">
                <img src="/png/cup.png"></img>
            </div>
            <div className="footerLead"></div>
            <div className="leaderBoardBody">
                <div className="leaderHeader">
                    <div className="headTextLead">Leaders</div>
                </div>
                <div style={{height: "100%"}}>
                    <div className="leadersList">
                        <Items items={currentItems} />
                    </div>
                    <div className="paginCont">
                        <ReactPaginate 
                            breakLabel = "..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            containerClassName="paginationCont"
                            pageClassName="pagePagin"
                            pageLinkClassName="pageLink"
                            activeClassName="activePagin"
                            
                            previousClassName="arrow"
                            nextClassName="arrow"
                            previousLinkClassName="arrowLink"
                            nextLinkClassName="arrowLink"
                            disabledClassName="disabledArrow"
                        />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default LeaderBoard;