import { createSlice } from "@reduxjs/toolkit"
export type ModalToShow = "bonus" | "leader" | "referrals" | "balance" | "none"

const initialState: {
    click: boolean
} = {
    click: false
}

const click = createSlice({
    name: 'state',
    initialState,
    reducers: {
       setClick(state) {
            state.click = !state.click;
       }
    }
})

export const {
    setClick
} = click.actions;

export default click.reducer;