import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import TopMenu from './components/TopMenu/TopMenu';
import Graph from './components/Graph/Graph';
import EnergyMenu from './components/EnergyMenu/EnergyMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from './reduxStorage/user/candles';
import Modal from './components/Modal/Modal';
// @ts-ignore
import ok from "./sound/growth.mp3"; 
// @ts-ignore
import notok from "./sound/down.mp3"; 

import useSound from 'use-sound';
import WS from './components/Graph/WS';
import { RootReducer } from './reduxStorage/configureStore';

function App() {
  const [canPlay, setCanPlay] = useState(true);
  const [displayApp, setDisplayApp] = useState(false);
  const dispatch = useDispatch()
  const bgAudio = useRef<null | HTMLAudioElement>(null);
  const [playOk, {stop: stopOk}] = useSound(ok, {
    loop: true
  });
  const [playNotOk, {stop: stopNotOk}] = useSound(notok, {
    loop: true
  });
  const [ws, isInit, setIsInit, isAlreadyFull, setIsAlreadyFull] = WS();
  const isCry = useSelector((storage: RootReducer) => {
    return storage.candles.isCry;
  })
  useEffect(() => {
    let window1: any = window
    const telegram = window1.Telegram.WebApp;
    telegram.ready();
    if(telegram.platform === "tdesktop" || telegram.platform === "web" || telegram.platform === "unknown") {
      return;
    }
    setDisplayApp(true);
    telegram.expand();
    const jsoned = JSON.stringify(telegram);
    const url = process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "tondegen.site"
    fetch(`https://${url}/auth`, {
      method: "POST",
      body: jsoned,
      headers: {
        "Content-Type": "application/json"
      }
    }).then((data) => {
      if(data.status !== 200) {
        return;
      }
      return data.json()
    }).then((data: any) => {
        dispatch(setUser(data))
    }).catch((e: any) => {
    })
  }, [])

  useEffect(() => {

    if(canPlay) {
      return;
    }
    stopOk();
    stopNotOk();
    if(!isCry) {
      playOk();
    } else {
      playNotOk();
    }
  }, [canPlay, isCry])

 const onCl = () => {
  setCanPlay((prev) => {
    if(prev){
      playOk();
      return false;
    }
    return false;
  })
 }

  return (
    <>
    {
      displayApp ? 
      <div className="App" onClick={onCl}>
        <Modal ws={ws as WebSocket | null} />
        <div className="workZone">
            <TopMenu></TopMenu>
            <Graph ws={ws} isInit={isInit} setIsInit={setIsInit} isAlreadyFull={isAlreadyFull} setIsAlreadyFull={setIsAlreadyFull}></Graph>
            <EnergyMenu></EnergyMenu>
        </div>
      </div> :
      <div className="NotAvailable">
        The application is only available on the mobile version.
      </div>
    }
    </>

  
  );
}

export default App;
