import { createSlice } from "@reduxjs/toolkit"

export type Friend = {
    id: number,
    name: string,
    earned: number,
    invited_count: number,
    balance: number,
    photo_path: string
}

const initialState: {
    friends: Friend[]
} = {
    friends: []
}

const friends = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setFriends(state, action:{
            type: string,
            payload: Friend[]
        }) {
            state.friends = action.payload;
        } 
    }
})

export const {
    setFriends
} = friends.actions;

export default friends.reducer;