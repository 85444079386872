import { useDispatch, useSelector } from "react-redux";
import { setModalToShow } from "../../../reduxStorage/modal/modal";
import "./Referrals.css";
import { useEffect, useState } from "react";
import { RootReducer } from "../../../reduxStorage/configureStore";
import FriendPiece from "./FriendPiece";

const Referrals = () => {
    const dispatch = useDispatch();
    const [isCopyAvailable, setIsCopyAvailable] = useState(true);
    const friends = useSelector((storage: RootReducer) => storage.frindes.friends);
    const user_ref_link = useSelector((storage: RootReducer) => storage.candles.user.ref_link)
    useEffect(() => {
        if(!isCopyAvailable) {
            const timeout = setTimeout(() => {
                setIsCopyAvailable(true);
                clearTimeout(timeout);
            }, 1000)
        }
    }, [isCopyAvailable])

    return (
        <div className="leaderBoardCont">
            <div className="crossLeader">
                <img src="/svg/cross.svg" onClick={() => {
                    dispatch(setModalToShow("none"))
                }}></img>
            </div>
            <div className="bgRef">
                <img src="/png/ref.png"></img>
            </div>
            <div className="footerLead">
                <div className="toLeaders" onClick={() => {
                    dispatch(setModalToShow("leader"));
                }}>
                    <div className="boostText" style={
                        {color: "white", fontSize: "14px"}
                    }>Leaderboard</div>
                </div>
            </div>
            <div className="leaderBoardBody">
                <div className="btn-balance" style={{display: "flex", alignItems: "center"}}>
                    <div className="friendsAmount">
                        <div className="friendsAmountTxt">{`${friends.length} Friends`}</div>
                        <div>
                            <img src="/svg/top.svg"></img>
                        </div>
                    </div>
                </div>
                <div className="refInvite" style={!isCopyAvailable ? {
                    backgroundColor: "rgb(255, 255, 255, 0.02)",
                    border: "1px solid rgb(255, 255, 255, 0.1)",
                    backdropFilter: "blur(25px)",
                    WebkitBackdropFilter: "blur(25px)"
                } : {
                    border: "1px solid #EBFF00"
                }} onClick={() => {
                    if(isCopyAvailable) {
                        setIsCopyAvailable(false);
                        navigator.clipboard.writeText(user_ref_link);
                    }
                }}>
                    <div className="boostText" style={isCopyAvailable ? 
                        {fontSize: "14px"} : 
                        {color: "rgba(255, 255, 255, 0.4)", fontSize: "14px"}
                    }>{isCopyAvailable ? "Invite a friend" : "Link copied"}</div>
                </div>
                <div className="friendsBodyCont" style={friends.length === 0 ? {display: "none"} : {}}>
                    <div className="friendsOverflowCont">
                    {
                        friends.map((friend, inx) => {
                            return <FriendPiece key={inx} data={friend}/>
                        })
                    }
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Referrals;