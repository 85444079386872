import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../reduxStorage/configureStore";
import "./Modal.css";
import ComingSoon from "./ComingSoon/ComingSoon";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import Charge from "./Charge/Charge";
import { animated, useSpring } from "@react-spring/web";
import Referrals from "./Referrals/Referrals";

const Modal = ({ws}: {ws: WebSocket | null}) => {
    const modalToShow = useSelector((storage: RootReducer) => {
        return storage.modal.modalShown
    })

    const shade1 = useSpring({
        opacity: modalToShow !== "none" ? 1 : 0,
    });
   
    return (
        <div>
            {modalToShow !== "none" && (
                <animated.div className="modalShade" style={shade1}>
                {
                    modalToShow === "balance" ? <Charge ws={ws}/> : 
                    (modalToShow === "bonus" ? <ComingSoon /> :
                    (modalToShow === "leader" ? <LeaderBoard /> :
                    (modalToShow === "referrals" ? <Referrals /> : <></>)))
                }
                </animated.div>
            )}
        </div>
        
    )
}

export default Modal;