import { useDispatch } from "react-redux";
import { setModalToShow } from "../../../reduxStorage/modal/modal";
import "./ComingSoon.css"

const ComingSoon = () => {
    const dispatch = useDispatch();
    return (
        <div className="modalBody">
            <div className="modalText">Coming Soon!</div>
            <div className="crossBody">
                <img src="/svg/cross.svg" onClick={() => {
                    dispatch(setModalToShow("none"))
                }}></img>
            </div>
        </div>
    )
}

export default ComingSoon;