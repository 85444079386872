import { useDispatch, useSelector } from "react-redux"
import { RootReducer } from "../../../reduxStorage/configureStore"
import { useEffect, useRef } from "react";
import { deleteNumber, setTimeouted } from "../../../reduxStorage/user/candles";
import "./Numbers.css";

const Numbers = () => {
    const numbers = useSelector((storage: RootReducer) => {
        return (storage.candles as any).numbers;
    })
    const timeouts = useRef<NodeJS.Timeout[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        numbers.forEach((number: any, index: number) => {
            if(number.timeouted){
                return
            } else {
                dispatch(setTimeouted(number.id))
            }
            const tm  = setTimeout(() => {
                dispatch(deleteNumber(number.id));
                clearTimeout(tm);
            }, 3500);
        });
    }, [numbers]);

  return (
    <div className="renderPlaceNimber">
      {numbers.map((number: any, index: number) => (
        <div key={index} style={{color: number.color === "green" ? "#32EF70" : "#F62E2E"}} className="numberActive number">{number.color=== "green" ? "+" + number.text : "-" + number.text}</div>
      ))}
    </div>
  );
}

export default Numbers;