import { useEffect, useRef, useState } from "react";
import "./Graph.css";
import { GenerateCandles, drawAxisOnce, drawCandles, getFloatTransform } from "./utils";
import * as d3 from "d3"
import { useDispatch, useSelector } from "react-redux";
import { setCandlesList, setDecreaseBalanceByAndIncreaseEnergy, setDecreaseEnergyBy, setIncreaseBalanceBy } from "../../reduxStorage/user/candles";
import { setCandlesPush, setIncreaseLastCandleIfNeededBy } from "../../reduxStorage/user/candles";
import Numbers from "./Numbers/Numbers";
import { setAddNumber } from "../../reduxStorage/user/candles";
import WS from "./WS";
import coinUp from "../../sound/coinup.mp3";
import coinDown from "../../sound/coindown.mp3";
import useSound from "use-sound";
export const MAX_CANDLES = 5;

const Graph = ({ws, isInit, setIsInit, isAlreadyFull, setIsAlreadyFull}) => {
    const [payCoinDown] = useSound(coinDown);
    const [playCoinUp] = useSound(coinUp);
    const candles = useSelector((storage) => storage.candles.candles);
    const isNew = useSelector((storage) => storage.candles.isNew);
    const user = useSelector((storage) => storage.candles.user);
    const click = useSelector((storage) => storage.click.click);
    const dispatch = useDispatch();
    const [isLol, setIsLol] = useState(true);
    const [clicks, setClicks] = useState(0);
    const svgRef = useRef(null);
    const screenWidth = window.innerWidth ;
    const height = window.innerHeight - 86 - 410;
    const widthSVG = d3.min([screenWidth, 460])
    const x = d3.scaleBand()
        .domain(Array.from({length: 14}).map((d, i) => i.toString()))
        .range([0, widthSVG*2])
        .paddingInner(0.1);
    const xAxis = d3.axisBottom(x)
        .tickSize(10)
        .tickPadding(5);
    
    const margin = { top: 10, right: 0, bottom: 30, bottomBig: 60, left: widthSVG/2 - x.bandwidth()/2};
    const pallette = {
        "green": "#32EF70",
        "red": "#F62E2E"
    }

    useEffect(() => {
        const interval1 = setInterval(() => {
            setClicks((clc) => {
                try {
                    if(ws !== null)
                        ws.send(JSON.stringify({event_type: "tap", data: clc.toString()}))
                } catch (error) {
                }

                if(clc === 0){
                    dispatch(setDecreaseBalanceByAndIncreaseEnergy({amount: 1, isNew: false}));
                    payCoinDown();
                }
                
                return 0;
            });
        }, 1000);
        
        return () => {
          clearInterval(interval1);
        };
    }, [ws]);

    useEffect(() => {
        
        const interval2 = setInterval(() => {
            dispatch(setCandlesPush({
                candleId: new Date().getTime(),
                maxCandles: MAX_CANDLES,
                isNew: true
            }));
        }, 3000);

        return () => {
            clearInterval(interval2);
            setIsInit(true);
            setIsAlreadyFull(false);
        }
    }, [])

    // useEffect(() => {
    //     backgroundMusic.play()
    //     return () => {
    //         backgroundMusic.pause();
    //         backgroundMusic.currentTime = 0;
    //     }
    // }, [])

    // useEffect(() => {
    //     coinUp.play();
    //     return () => {
    //         coinUp.pause();
    //         coinUp.currentTime = 0;
    //     }
    // }, [play])

    useEffect(() => {
        if(candles.length > 1 && isNew) {
            const diff = candles[candles.length - 2].exit - candles[candles.length - 2].enter
            dispatch(setIncreaseBalanceBy(diff))
            if(diff > 0) {
                dispatch(setAddNumber({
                    color: "green",
                    text: diff.toString(),
                }))
                playCoinUp();
                if(window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
                    window.Telegram.WebApp.HapticFeedback.impactOccurred("light");
                }
                // setPlay(prev => !prev);
            }
            if (svgRef.current !== null) {
                const svg = d3.select(svgRef.current).select(".init").select('.outer')
                const tr = getFloatTransform(svg);
                svg
                    .transition().duration(true ? 200: 0)
                    .attr("transform", "translate(" + (tr + x.bandwidth()*(-1.1)) + ", " + margin.top + ")")
                    
                if(candles.length + 1  >= MAX_CANDLES) {
                    const candlesG = svg
                        .select(".graph")
                    const trC = getFloatTransform(candlesG);
                    if(trC >= 0.000001 || isAlreadyFull){
                        candlesG
                            .transition()
                            .duration(0)
                            .attr("transform", "translate(" + (trC + x.bandwidth()*(1.1)) + ", 0)")
                    } else {
                        candlesG
                            .transition()
                            .duration(0)
                            .attr("transform", "translate(0.0001, 0)")
                    }
                    
                }
                
               
            }
        }
       if(candles.length > 1 && !isNew) {
        setClicks((clc)=> {
            if(clc === 0) payCoinDown();
            return clc;
        })

       }
    }, [candles, svgRef.current, isNew, isAlreadyFull])
    
    const handleClick = () => {
        if(user.energy >= 1) {
            setClicks(prev => prev + 1);
            dispatch(setIncreaseLastCandleIfNeededBy({
                amount: 1,
                isNew: false
            }))
            dispatch(setDecreaseEnergyBy(1));
            setIsLol(false);
        }
    };

    useEffect(() => {
        if(isLol) {
            return;
        }
        handleClick();
    }, [click])

    useEffect(() => {
        if(svgRef.current === null) return;
        const svg = d3.select(svgRef.current)
            .attr("width", widthSVG)
            .attr("height", height + margin.top + margin.bottomBig)

        svg
            .selectAll(".init")
            .data([0])
            .enter()
            .append("g")
            .attr("class", "init")
            .append("g")
            .attr("class", "outer")
            .attr("transform", `translate(${margin.left},${margin.top})`)
            .append("g")
            .attr("class", "graph")
    }, [svgRef.current])

    useEffect(() => {
        if (svgRef.current === null) return;
        const svg1 = d3.select(svgRef.current).select('.init')
        const svg = svg1.select('.outer').select('.graph')
        const mf = d3.max(candles, d => d.high)
        const m = mf ? mf : 0;
        const md = m > 10 ? m : 10;
        const mmf = d3.min(candles, d => d.low)
        const mm = mmf ? mmf : 0;
        if(isInit) {
            const trC = getFloatTransform(svg1);
            if(candles.length - 1 > 0) {
                svg1
                    .attr("transform", "translate(" + (trC - x.bandwidth()*(1.1)*(candles.length - 1)) + ", 0)")
            }
            setIsInit(false);
          
        }
        const y = d3.scaleLinear()
            .domain([mm, md])
            .range([height, margin.top]);

        drawAxisOnce(svg, y, xAxis, mm, md, margin, height, widthSVG)
      
        drawCandles(svg, candles, x, y, pallette, isNew)
        
    }, [candles, svgRef.current, isInit])


    return (
        <div className="graphOuter" onTouchEnd={handleClick} onTouchCancel={handleClick} style={{width: widthSVG, height: height + margin.top + margin.bottomBig}}>
            <div className={candles.length > 0 && candles[0] && candles[0].high > 0 ? "tapOuter tapOuterToCorner" : "tapOuter"} >
                <img src="/svg/tap1.svg" width="100%"></img>
            </div>
            <Numbers />
            <svg ref={svgRef}>
            </svg>
        </div>
    )
}

export default Graph;