import { useDispatch, useSelector } from "react-redux"
import "./TopMenu.css"
import { RootReducer } from "../../reduxStorage/configureStore"
import { setModalToShow } from "../../reduxStorage/modal/modal";
import { animated, useSpring } from "@react-spring/web";

export function formatNumberWithCommas(num: number, withDecimal: boolean) {
    let formattedNum = "";
    if(withDecimal) {
        formattedNum = num.toFixed(2).toString()
        const [whole, decimal] = formattedNum.split('.');
        const formattedWhole = whole.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedWhole}.${decimal}`;
    } else {
        formattedNum = num.toFixed(0).toString();
        const [whole, _] = formattedNum.split('.');
        return whole.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
}

const TopMenu = () => {
    const balance = useSelector((storage: RootReducer) => {
        return storage.candles.user.balance
    })
    const dispatch = useDispatch()
    const props = useSpring({
        from: { number: 0 },
        to: { number: balance },
        config: { duration: 500 },
    });
    return (
        <div>
            <div className="topMenu">
                <button className="btn btn1" onClick={() => {
                    dispatch(setModalToShow("bonus"))
                }}>
                    <div className="btnInner1">
                        <div className="balance1">Bonus</div>
                    </div>
                </button>
                <button className="btn btn2" onClick={() => {
                    dispatch(setModalToShow("leader"))
                }}>
                    <div className="btnInner">
                        <div className="crownAbs">
                            <img style={{width: "100%", height: "100%"}} src="/svg/crown1.svg"></img>
                        </div>
                    </div>
                </button>
                <button className="btn btn1" onClick={() => {
                    dispatch(setModalToShow("referrals"))
                }}>
                    <div className="btnInner1">
                        <div className="balance1">Referrals</div>
                    </div>
                </button>
            </div>
            <div className="balanceTop">
                <button className="btn-balance">
                    <div className="btnInner1">
                        <animated.div className="balance">{props.number.to(n => formatNumberWithCommas(n, true))}</animated.div>
                    </div>
                </button>
            </div>
           
        </div>
        

    )
}

export default TopMenu;