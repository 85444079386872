import { useEffect, useState } from "react";

const Timer = ({lastRecharge}: {lastRecharge: number}) => {
    const [timeRemaining, setTimeRemaining] = useState(lastRecharge + 24 * 60 * 60);
    const [date, _] = useState(Math.floor((new Date()).getTime() / 1000));

    useEffect(() => {
      const updateTimer = () => {
        setTimeRemaining(prev => {
            return prev - 1
        });
      };
  
      const intervalId = setInterval(updateTimer, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  
    const formatTime = (seconds: number) => {
        let diff = seconds - date >= 0 ? seconds - date : 0;
    
        const hours = Math.floor(diff / 3600);
        const minutes = Math.floor((diff % 3600) / 60);
        const secs = diff % 60;
      
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };
  
    return (
      <div style={{color: "rgb(255, 255, 255, 0.4)"}}>
        {formatTime(timeRemaining)}
      </div>
    );
  };

  export default Timer;