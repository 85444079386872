import { createSlice } from "@reduxjs/toolkit"
export type ModalToShow = "bonus" | "leader" | "referrals" | "balance" | "none"

export type TopUser = {
    id: number,
    name: string,
    balance: number,
    invited_count: number,
    place: number
}

export type List = {
    top: TopUser[],
    user: TopUser
}

const initialState: {
    list: List
} = {
    list: {
        top: [],
        user: {
            id: -1,
            name: "name",
            balance: 1231230,
            invited_count: 0,
            place: 23423
        }
    }
}

const leaderboard = createSlice({
    name: 'state',
    initialState,
    reducers: {
       setLeaderBoard(state, action: {
        type: string, 
        payload: List
       }) {
            state.list = {
                top: action.payload.top.filter((item, inx) => {
                    return item.id !== action.payload.user.id
                }),
                user: action.payload.user
            }
       }
    }
})

export const {
    setLeaderBoard
} = leaderboard.actions;

export default leaderboard.reducer;