import { useDispatch, useSelector } from "react-redux"
import "./Charge.css"
import { RootReducer } from "../../../reduxStorage/configureStore";
import { formatNumberWithCommas } from "../../TopMenu/TopMenu";
import { setEnergyCapacity, setLastCharge } from "../../../reduxStorage/user/candles";
import Timer from "./Timer";
import { animated, useSpring } from "@react-spring/web";
import { setModalToShow } from "../../../reduxStorage/modal/modal";

const Charge = ({ws}: {ws: WebSocket | null}) => {
    const dispatch = useDispatch();
    const balance = useSelector((storage: RootReducer) => {
        return storage.candles.user.balance;
    })
    const energy = useSelector((storage: RootReducer) => {
        return storage.candles.user.energy;
    })
    const energyMax = useSelector((storage: RootReducer) => {
        return storage.candles.user.energy_capacity;
    })
    const lastRecharge = useSelector((storage: RootReducer) => {
        return storage.candles.user.last_recharge
    })
    const propsBalance = useSpring({
        from: { number: 0 },
        to: { number: balance },
        config: { duration: 500 },
    });
    const propsAvailableEnergy = useSpring({
        from: { number: 0 },
        to: { number: energy },
        config: { duration: 500 },
    })
    const propsEnergyMax = useSpring({
        from: { number: 0 },
        to: { number: energyMax },
        config: { duration: 500 },
    })
    const propsNewEnergyMax = useSpring({
        from: { number: 0 },
        to: { number: energyMax + 1000 },
        config: { duration: 500 },
    })
    const toBurn = 2**(Math.trunc(energyMax) / 1000-1)*1000;
    const proprsBurn = useSpring({
        from: { number: 0 },
        to: { number: toBurn },
        config: { duration: 500 },
    })
    const isIncCapAvailable = balance >= toBurn;

    const nowD = Math.trunc((new Date()).getTime()/1000);
    const isDailyAvailable = nowD - lastRecharge >= 3600*24;
    return (
        <div className="chargeBody">
       
            <div className="fireBg">
                <img src="/png/fire.png"></img>
            </div>
            <div className="balanceTop" style={{marginTop:"73px", width: "calc(100% - 60px)", marginLeft: "30px"}}>
                <button className="btn-balance">
                    <div className="btnInner1">
                        <animated.div className="balance">{propsBalance.number.to(n => formatNumberWithCommas(n, true))}</animated.div>
                    </div>
                </button>
            </div>
            <div className="energyStateCont">
                <div className="energyStdCont">
                    <div className="powerLabel">
                        <div className="svgControl">
                            <img src="/svg/power.svg"></img>
                        </div>
                        <div className="textStd">Max Power:</div>
                    </div>                        
                    <animated.div className="textStd" style={{fontSize: "16px"}}>{propsEnergyMax.number.to(n => formatNumberWithCommas(n, false))}</animated.div>
                </div>
                <div className="energyStdCont">
                    <div className="powerLabel">
                        <div className="svgControl">
                            <img src="/svg/lighn.svg"></img>
                        </div>
                        <div className="textStd">Available:</div>
                    </div>
                    <animated.div className="textStd" style={{fontSize: "16px"}}>{propsAvailableEnergy.number.to(n => formatNumberWithCommas(n, true))}</animated.div>
                </div>
            </div>
            <div className="rechargeBalance" style={!isDailyAvailable ? {
                backgroundColor: "rgb(255, 255, 255, 0.02)",
                border: "1px solid rgb(255, 255, 255, 0.1)",
                backdropFilter: "blur(25px)",
                WebkitBackdropFilter: "blur(25px)"
            } : {
                border: "1px solid #EBFF00"
            }} onClick={() => {
                if(isDailyAvailable) {
                    dispatch(setLastCharge(Math.trunc((new Date()).getTime() / 1000)));
                    if(ws !== null) {
                        ws.send(JSON.stringify({event_type: "recharge", data: ""}));
                    }
                }
            }}>
                <div className="boostText">{isDailyAvailable ? "Recharge" : <Timer lastRecharge={lastRecharge}/>}</div>
            </div>
            <div className="capacityCont">
                <div className="chargeImage">
                    <img style={{width: "100%", height: "100%", display: "block"}} src="/svg/charge.svg"></img>
                </div>
                <div className="capTextCont">
                    <div className="capTextText">
                        <div className="capTTT">
                            <div style={{display: "flex"}}>
                                <div style={{marginRight: "5px"}}>Burn</div>
                                <animated.div style={{fontWeight: "600", marginRight: "5px"}}>{proprsBurn.number.to(n => formatNumberWithCommas(n, false))}</animated.div>
                            </div>  
                            <div className="textNearFire">tokens!</div>
                        </div>
                        <div className="capTTT1">
                            <div style={{fontSize: "22px", marginLeft: "auto"}}>Get</div>
                            <animated.div style={{fontWeight: "600", fontSize: "36px", color: "rgb(235, 255, 0)"}}>{propsNewEnergyMax.number.to(n => formatNumberWithCommas(n, false))}</animated.div>
                            <div style={{fontSize: "20px",  marginLeft: "auto"}}>power!</div>
                        </div>   
                    </div>
                    <div className="boostCapacity"  style={!isIncCapAvailable ? {
                        backgroundColor: "#1b1b1b",
                        border: "1px solid #323232",
                     } : {
                        border: "1px solid #EBFF00",
                     }} onClick={() => {
                    if(isIncCapAvailable) {
                        dispatch(setEnergyCapacity())
                        if(ws !== null) {
                            ws.send(JSON.stringify({event_type: "cap", data: ""}))
                        }
                    }
                }}>
                    <div className="boostTextCap" style={!isIncCapAvailable ? {
                        color: "rgba(255, 255, 255, 0.4)"
                    } : {
                        color: "#080808",
                    }}>Boost Capacity</div>
                    </div>
                </div>
            </div>
            <div className="crossLeader">
                <img src="/svg/cross.svg" onClick={() => {
                    dispatch(setModalToShow("none"))
                }}></img>
            </div>
        </div>
       
    )
}

export default Charge;