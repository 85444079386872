// const (
//     EventAuth    WebsocketEventType = "auth"
//     EventStop    WebsocketEventType = 
//     EventTap     WebsocketEventType = 
//     EventCandles WebsocketEventType = 
//     EventError   WebsocketEventType = 
//     EventLog     WebsocketEventType = 
//     EventClaim   WebsocketEventType = 
//   )

import { createSlice } from "@reduxjs/toolkit"
import { Candle } from "../../components/Graph/types"
import { Mood } from "../../components/EnergyMenu/EnergyMenu"


export type NumberBubble = {
    color: "green" | "red",
    text: string,
    id: number,
    timeouted: boolean
}

export type WebsocketUserInitData = {
    auth_key:  string  
    balance: number
    pending_balance: number
    invited_amount: number
    last_max_balance: number
    last_min_energy: number
    energy: number
    energy_capacity: number,
    last_recharge: number
    progress: number,
    ref_link: string
}

export type WebsocketEvent = {
    event_type:  "auth" | "stop" | "tap" | "candles" | "error" | "log" | "claim" | "top" | "ref_top"
    data:        string
}

type CandleWs = {
    start_progress: number,
    end_progress: number,
    high: number,
    low: number
}

const initialState: {
    candles: Candle[],
    isNew: boolean,
    numbers: NumberBubble[],
    mood: Mood,
    id: number,
    user: WebsocketUserInitData,
    durBalance: number,
    durEnergy: number,
    isCry: boolean,
    ws: null | WebSocket,
} = {
    candles: [],
    mood: "std",
    isCry: false,
    isNew: false,
    numbers: [],
    id: 0,
    user: {
        auth_key: "",
        balance: 0,
        pending_balance: 0,
        invited_amount: 0,
        last_max_balance: 0,
        last_min_energy: 0,
        energy: 1000,
        energy_capacity: 1000,
        last_recharge: 0,
        progress: 0,
        ref_link: ""
    },
    durBalance: 259200,
    durEnergy: 10800,
    ws: null,
}

const candles = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setMood(state, action: {
            type: string,
            payload: Mood
        }) {
            state.mood = action.payload;
            if(action.payload !== "tired") {
                state.isCry = action.payload === "cry";
            }
        },
        setEnergyCapacity(state, action: {
            type: string,
        }) {
            const toBurn = 2**(Math.trunc(state.user.energy_capacity) / 1000-1)*1000;
            if(state.user.balance - toBurn >= 0) {
                state.user.balance -= toBurn;
                state.user.last_max_balance = state.user.balance;
                state.user.energy_capacity = state.user.energy_capacity + 1000
            }
        },
        setLastCharge(state, action: {
            type: string,
            payload: number
        }) {
            state.user.last_recharge = action.payload;
            state.user.energy = state.user.energy_capacity;
        },
        setWS(state, action: {
            type: string,
            payload: WebSocket
        }){
            state.ws = action.payload;
        },
        setCandlesPush(state, action: {
            type: string,
            payload: {
                candleId: number,
                maxCandles: number,
                isNew: boolean
            }
        }) {
            const newArray = [...state.candles];
            if (newArray.length !== 0) {
                const lastExit = newArray[newArray.length - 1].exit;
                if(lastExit < 0.5) return;
                const lastCandle = {
                    enter: lastExit,
                    exit: lastExit,
                    high: lastExit,
                    low: lastExit,
                    candleId: action.payload.candleId,
                }
                newArray.push(lastCandle);
                if(newArray.length >= action.payload.maxCandles) {
                    newArray.shift();
                }
    
                state.isNew = action.payload.isNew;
                state.candles = newArray;
            }
           
            
        },
        setCandlesUpdateLast(state, action: {
            type: string,
            payload: Candle
        }) {
            state.candles[state.candles.length - 1] = action.payload;
        },
        setCandlesList(state, action: {
            type: string, 
            payload: {
                candles: CandleWs[],
                max_candles: number
            }
        }) {
            let newCandles: Candle[] = []
            const candles = action.payload.candles;
            const max = action.payload.max_candles;
            for(let i = 0; i < candles.length; i++) {
                if(candles[i].low < 1 && candles[i].high < 1) {
                    continue
                }
                newCandles.push({
                    low: candles[i].low,
                    high: candles[i].high,
                    enter: candles[i].start_progress,
                    exit: candles[i].end_progress,
                    candleId: (new Date()).getDate() - i
                })
            }
            if(newCandles.length - 1 > max) {
                state.candles = newCandles.slice(newCandles.length - max + 1)
            } else {
                state.candles = newCandles;
            }
        },
        setDecreaseLastCandleIfNeededBy(state, action: {
            type: string,
            payload: {
                amount: number,
                isNew: boolean
            }
        }) {
            
        },
        setIncreaseLastCandleIfNeededBy(state, action: {
            type: string,
            payload: {
                amount: number,
                isNew: boolean
            }
        }) {
            const candles = [...state.candles];
            let lastCandle;
            if(candles.length > 0) {
                lastCandle = candles[candles.length - 1];
            } else {
                lastCandle = {
                    exit: 0,
                    enter: 0,
                    low: 0,
                    high: 0,
                    candleId: 0
                }
                candles.push(lastCandle);
            }
            lastCandle.exit += action.payload.amount;
            if (lastCandle.high < lastCandle.exit) {
                lastCandle.high = lastCandle.exit;
            }

            state.candles = candles;
            state.isNew = action.payload.isNew;
        },
        setAddNumber(state, action: {
            type: string,
            payload: NumberBubble
        }) {
            state.numbers.push({...action.payload, id: state.id, timeouted: false})
            state.id += 1
        },
        setTimeouted(state, action: {
            type: string,
            payload: number
        }) {
            for(let i = 0; i < state.numbers.length; i++) {
                if(state.numbers[i].id === action.payload) {
                    state.numbers[i].timeouted = true;
                    return;
                }
            }
            state.numbers.forEach((val, inx) => {
                
            })
        },
        deleteNumber(state, action: {
            type: string,
            payload: number
        }) {
            state.numbers = state.numbers.filter((num, i) => num.id !== action.payload)
        },
        setUser(state, action: {
            type: string,
            payload: WebsocketUserInitData
        }) {
            state.user = action.payload;
        },
        setIncreaseBalanceBy(state, action: {
            type: string,
            payload: number
        }) {
            if(action.payload > 0) {
                state.user.balance += action.payload;
                state.user.last_max_balance = state.user.balance;
            }
        },
        setDecreaseEnergyBy(state, action: {
            type: string,
            payload: number
        }) {
            if(state.user.energy - action.payload >= 0) {
                state.user.energy -= action.payload;
                state.user.last_min_energy = state.user.energy;
            }
        },
        setDecreaseBalanceByAndIncreaseEnergy(state, action: {
            type: string,
            payload: {
                amount: number,
                isNew: boolean,
            }
        }) {
            const candles = state.candles;
            const invited_amount = state.user.invited_amount > 100 ? 100 : state.user.invited_amount;

            const x = state.user.last_max_balance*(1.0 - invited_amount/100.0)/state.durBalance;
            if(candles.length > 0 && candles[candles.length - 1].exit >= action.payload.amount) {
                const lastCandle = candles[candles.length - 1];
                lastCandle.exit -= action.payload.amount;
                if(lastCandle.exit < 0) {
                    lastCandle.exit = 0;
                }
                if (lastCandle.low > lastCandle.exit) {
                    lastCandle.low = lastCandle.exit;
                }
                state.candles[state.candles.length - 1] = lastCandle;
                state.isNew = action.payload.isNew;
            }
            if (state.user.balance - x > state.user.last_max_balance * (invited_amount / 100)) {
                let numToPush = x < 0.001 ? "0.001" : x.toFixed(3);
                if(x >= 0.00001) {
                    state.numbers.push({
                        text: numToPush,
                        color: "red",
                        id: state.id + 1,
                        timeouted: false
                    })
                    state.id += 1
                    state.user.balance -= x;
                }  
            } else {
                state.user.balance = state.user.last_max_balance * (invited_amount / 100);
            }

            const y = (state.user.energy_capacity - state.user.last_min_energy) / state.durEnergy;
            if(state.user.energy + y < state.user.energy_capacity){
                state.user.energy += y;
            } else {
                state.user.energy = state.user.energy_capacity;
            }
        }
    }
})

export const {
    setTimeouted, 
    setCandlesPush, 
    setCandlesUpdateLast, 
    setCandlesList, 
    setDecreaseLastCandleIfNeededBy, 
    setIncreaseLastCandleIfNeededBy, 
    setAddNumber, 
    deleteNumber,
    setUser, setIncreaseBalanceBy, setDecreaseEnergyBy, setDecreaseBalanceByAndIncreaseEnergy, 
    setWS,
    setEnergyCapacity,
    setLastCharge,
    setMood
} = candles.actions;

export default candles.reducer;