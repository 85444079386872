import "./Position.css"

const getCls = (inx: number, isMe: boolean) => {
    if(isMe) {
        return "mePosition"
    } else {
        switch(inx) {
            case 1:
                return "firstPosition"
            case 2:
                return "topPosition"
            case 3:
                return "topPosition"
            default:
                return ""
        }
    }
}

function formatNumberWithCommas(num: number) {
    if (num >= 1000000) {
        const numInThousands = (num / 1000).toFixed(0);
        const formattedInThousands = numInThousands.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedInThousands}k`;
    } else {
        const formattedNum = num.toFixed(0).toString();
        const formattedWhole = formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedWhole}`;
    }
}

function formatNumber(n: number) {
    if(n === 1) {
        return <img src="/svg/top.svg"></img>
    } else if(n === 2) {
        return <img src="/svg/second.svg"></img>
    } else if(n === 3) {
        return <img src="/svg/third.svg"></img>
    }
    if (n < 1000) {
        return n.toString();
    } else if (n < 10000) {
        return (Math.floor(n / 100) / 10).toFixed(1) + 'k';
    } else if (n < 1000000) {
        return Math.floor(n / 1000) + 'k';
    } else {
        return Math.floor(n / 1000000) + 'M';
    }
}

const Position = ({data}: {data: {
    isMe: boolean,
    place: number,
    invited_count: number,
    balance: number,
    name: string
}}) => {

    const addCl = getCls(data.place, data.isMe)

    return (
        <div className={"posLead" + (addCl !== "" ? ` posLead-${addCl}` : "")}>
            <div className="dataL">
                <div className={"inxL" + (addCl !== "" ? ` inxL-${addCl}` : "")}>{formatNumber(data.place)}</div>
                <div className="nameL">{data.isMe ? "You" : (data.name !== "" ? data.name : "Anon")}</div>
                <div className={"friends" + (addCl !== "" ? ` friends-${addCl}` : "")}>{data.invited_count.toString() + " friends"}</div>
                <div className="balanceL">{formatNumberWithCommas(data.balance)}</div>
            </div>
        </div>
    )
}

export default Position;