import { Friend } from "../../../reduxStorage/friends/friends";
import "./FriendPiece.css";

function formatNumberWithCommas(num: number) {
    if (num >= 1000000) {
        const numInThousands = (num / 1000).toFixed(0);
        const formattedInThousands = numInThousands.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedInThousands}k`;
    } else {
        const formattedNum = num.toFixed(0).toString();
        const formattedWhole = formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return `${formattedWhole}`;
    }
}

const FriendPiece = ({data}: {data: Friend}) => {
    return (
        <div className="friendCont">
            <div className="nickAndBalance">
                <div className="nickFriend">{data.name}</div>
                <div className="balanceFriend">{formatNumberWithCommas(data.balance)}</div>
            </div>
            <div className="hisFriends">{"(" + data.invited_count.toString() + " friends)"}</div>
            <div className="earnedF">{"+" + formatNumberWithCommas(data.earned)}</div>
        </div>
    )
}

export default FriendPiece;