import { createSlice } from "@reduxjs/toolkit"
export type ModalToShow = "bonus" | "leader" | "referrals" | "balance" | "none"

const initialState: {
    modalShown: ModalToShow
} = {
    modalShown: "none"
}

const candles = createSlice({
    name: 'state',
    initialState,
    reducers: {
       setModalToShow(state, action: {
        type: string, 
        payload: ModalToShow
       }) {
            state.modalShown = action.payload;
       }
    }
})

export const {
    setModalToShow
} = candles.actions;

export default candles.reducer;