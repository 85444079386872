import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../reduxStorage/configureStore";
import { WebsocketEvent, setCandlesList } from "../../reduxStorage/user/candles";
import { MAX_CANDLES } from "./Graph";
import { Candle } from "./types";
import { setLeaderBoard } from "../../reduxStorage/leaderboard/leaderboard";
import { setFriends } from "../../reduxStorage/friends/friends";

const WS = () => {
    const candles = useSelector((storage: RootReducer) => storage.candles);
    const [isConnected, setIsConnected] = useState(false);
    const [ws, setWS] = useState<null | WebSocket>(null);
    const [isAlreadyFull, setIsAlreadyFull] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const dispatch = useDispatch();
    const [inter, setInter] = useState<null | NodeJS.Timer>(null);


    function initWs(url: URL, reconnect: boolean){
        const ws = new WebSocket(url.toString());
        ws.onopen = (ev) => {
            ws.send(JSON.stringify({event_type: "auth", data: JSON.stringify((window as any).Telegram.WebApp)}));
            setWS(ws);
            setInter(() => {
                return setInterval(() => {
                    ws.send("ping");
                }, 5000);
            });

            if(reconnect){
                ws.send(JSON.stringify({event_type: "recover", data: JSON.stringify(candles.user)}))
            }
        }

        ws.onmessage = (ev) => {
            const data: WebsocketEvent = JSON.parse(ev.data);

            if(data.event_type === "candles" && !reconnect){
                const c = JSON.parse(data.data);
                dispatch(setCandlesList({
                    candles: c,
                    max_candles: MAX_CANDLES
                }))
                setIsInit(true);
                let newCandles: Candle[] = []
                for(let i = 0; i < c.length; i++) {
                    if(c[i].low < 1 && c[i].high < 1) {
                        continue
                    }
                    newCandles.push({
                        low: c[i].low,
                        high: c[i].high,
                        enter: c[i].start_progress,
                        exit: c[i].end_progress,
                        candleId: (new Date()).getDate() - i
                    })
                }
                if(newCandles.length + 1 >= MAX_CANDLES) {
                    setIsAlreadyFull(true)
                }
            }

            if(data.event_type === "top" && !reconnect) {
                const c = JSON.parse(data.data);
                dispatch(setLeaderBoard(c));
            }

            if(data.event_type === "ref_top" && !reconnect) {
                const c = JSON.parse(data.data);
                dispatch(setFriends(c));
            }

        }

        ws.onclose = () => {
            setInter((prev) => {
                if (prev === null) return prev;
                clearInterval(prev);
                return null
            })

            const int = setInterval(() => {
                let window1: any = window
                const telegram = window1.Telegram.WebApp;
                const jsoned = JSON.stringify(telegram);
                const url = process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "tondegen.site"
                fetch(`https://${url}/auth`, {
                  method: "POST",
                  body: jsoned,
                  headers: {
                    "Content-Type": "application/json"
                  }
                }).then((data) => {
                  if(data.status !== 200) {
                    return {auth_key: ""};
                  }
                  return data.json()
                }).then((data: any) => {
                    const key = data.auth_key;
                    if(key === ""){
                        return;
                    }

                    const url0 = process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "tondegen.site"
                    const url = new URL(`wss://${url0}/ws`);
                    url.searchParams.set("auth", key);

                    initWs(url, true);
                    clearInterval(int);
                }).catch((e) => {});
            }, 15000);
        }
    }

    useEffect(() => {
        setIsConnected((old) => {
            if(old) return true;
            if(candles.user.auth_key) {
                try {
                    const url1 = process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "tondegen.site"
                    const url = new URL(`wss://${url1}/ws`);
                    url.searchParams.set("auth", candles.user.auth_key);
            
                    initWs(url, false);

                    
                    return true;
                } catch (error: any) {
                }
                
            }
            
            return false;
        })
    }, [isConnected, candles.user])

    return [ws, isInit, setIsInit, isAlreadyFull, setIsAlreadyFull];
}

export default WS;