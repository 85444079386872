import { useEffect, useState } from "react"
import { RootReducer } from "../../reduxStorage/configureStore"
import "./EnergyMenu.css"
import { useDispatch, useSelector } from "react-redux"
import { Candle } from "../Graph/types"
import { MAX_CANDLES } from "../Graph/Graph"
import { setClick } from "../../reduxStorage/click/click"
import { setModalToShow } from "../../reduxStorage/modal/modal"
import { animated, useSpring } from "@react-spring/web"
import { setMood } from "../../reduxStorage/user/candles"

export type Mood = "std" | "satisfied" | "lost" | "happy" | "cry" | "start" | "losing" | "tired"
type MoodConfig = {
    img: string,
    text: string
}
const getMoodData = (mood: Mood): MoodConfig => {
    switch(mood) {
        case "tired": 
            return {
                img: "/degenFace/tired1.svg",
                text: "I'm tired..."
            }
        case "start":
            return {
                img: "/degenFace/std1.svg",
                text: "Let's start?"
            }
        case "std":
            return {
                img: "/degenFace/std1.svg",
                text: "Progress."
            }
        case "losing":
            return {
                img: "/degenFace/std1.svg",
                text: "It's bad, man."
            }
        case "satisfied":
            return {
                img: "/degenFace/satisfied1.svg",
                text: "Good."
            }
        case "lost":
            return {
                img: "/degenFace/lost1.svg",
                text: "..."
            }
        case "happy":
            return {
                img: "/degenFace/happy1.svg",
                text: "Incredible!"
            }
        case "cry":
            return {
                img: "/degenFace/cry1.svg",
                text: "Don't do that, bro!"
            }
    }
}

export const defineMood = (candles: Candle[], isTired: boolean): Mood => {
    if(candles.length === 0) {
        return "start"
    }
    if(isTired) {
        return "tired"
    }
    const progress = candles.map((candle: Candle) => {
        return candle.exit - candle.enter;
    })
    if(progress.length >= MAX_CANDLES - 1) {
        let cry = true;
        let happy = true;
        candles.forEach((val, i) => {
            if(val.exit > val.enter) {
                cry = false;
            }
            if(val.high > val.exit) {
                happy = false;
            }
        })
        if(cry) {
            return "cry"
        }
        if (happy) {
            return "happy"
        }
    }

    if(progress.length > 2 && progress[progress.length - 1] > -4 && progress[progress.length - 2] > 0) {
        return "satisfied"
    }
    if(progress[progress.length - 1] > 0) {
        return "std"
    }
    return "losing"
}

function interpolateColor(percent: number) {
    if (percent < 0 || percent > 100) {
        return `linear-gradient(90deg, #EBFF00, #EBFF00)`;
    }

    const fixedColor = { r: 235, g: 255, b: 0 }; // #FF1F00
    // Начальный и конечный цвета в формате RGB
    const startColor =  { r: 246, g: 46, b: 46 }// #EBFF00
    const endColor = { r: 235, g: 255, b: 0 };   // rgb(246, 46, 46)

    const ratio = percent / 100;

    // Интерполяция каждого компонента цвета
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * ratio);
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * ratio);
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * ratio);

    // Форматируем результат в HEX
    const toHex = (value: number) => value.toString(16).padStart(2, '0').toUpperCase();
    const interpolatedColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

    // Формируем строку линейного градиента
    const fixedColorHex = `#${toHex(fixedColor.r)}${toHex(fixedColor.g)}${toHex(fixedColor.b)}`;
    return `linear-gradient(90deg, ${fixedColorHex}, ${interpolatedColor})`;
}

const EnergyMenu = () => {
    const energy = useSelector((storage: RootReducer) => {
        return storage.candles.user.energy
    })
    const maxEnergy = useSelector((storage: RootReducer) => {
        return Math.trunc(storage.candles.user.energy_capacity);
    })
    const dispatch = useDispatch()
    const candles = useSelector((storage: RootReducer) => {
        return storage.candles.candles;
    })
    // const [text, setText] = useState("Hello, world!")
    const mood = useSelector((storage: RootReducer) => {
        return storage.candles.mood;
    })
    const moodData = getMoodData(mood);
    const textSpring = useSpring({
        from: { opacity: 0, transform: 'translateY(-20px)' },
        to: { opacity: 1, transform: 'translateY(0px)' },
    });
    const props = useSpring({
        from: { number: 0 },
        to: { number: energy },
        config: { duration: 500 },
    });
    // const energyStr = energy>= 1000 ? "1000/1000" : energy.toFixed(2) + "/1000"
    useEffect(() => {
        dispatch(setMood(defineMood(candles, energy < 1)));
        // setText((prevText) => (prevText === 'Hello, world!' ? 'Goodbye, world!' : 'Hello, world!'))
    }, [candles, energy])

    return (
        <div className="energyMenuOuter">
            <div className="energy-bg">
                <div className="degenPictures">
                    <div className="degenFace">
                        <img style={{width: "100%", transform: "translate(0, -45px)"}} onTouchEnd={() => {
                            dispatch(setClick())
                        }} onTouchCancel={() => {
                            dispatch(setClick())}} src={moodData.img}></img>
                    </div>
                    <div className="degenThout">
                        <div className="degenThoutText" onTouchEnd={() => {
                            dispatch(setClick())
                        }} onTouchCancel={() => {
                            dispatch(setClick())}}>
                            <animated.div style={textSpring}>{moodData.text}</animated.div>
                        </div>
                    </div>
                </div>
                <div className="degenEnergy" onClick={() => {
                    dispatch(setModalToShow("balance"))
                }}>
                    <div className="progress">
                        <div className="lightCont">
                            <img style={{width: "100%", height: "100%"}} src="/svg/light1.svg"></img>
                        </div>
                        <animated.div className="progressText">{props.number.to(n => energy >= maxEnergy ? `${maxEnergy}/${maxEnergy}` : energy.toFixed(2) + `/${maxEnergy}`)}</animated.div>
                        <div className="plusEnergy">
                            <div className="chargeText">Charge</div>
                            <div className="plusContEnerg">
                                <img style={{width: "100%", height: "100%"}} src="/svg/Plus1.svg"></img>
                            </div>
                        </div>
                    </div>
                    <div className="progressBar">
                        <div className="progressState" style={{width: (energy/maxEnergy*100).toFixed(1) + "%", background: interpolateColor(Math.trunc(energy/maxEnergy*100))}}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnergyMenu;