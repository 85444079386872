import { Candle } from "./types";
import * as d3 from "d3";

const constants = {
    DOWN: 3,
    UP: 2
}

export function GenerateCandles(amount: number): Candle[] {
    let ret: Candle[] = [];
    let low = 0;
    let high = 0;
    let exit = 0;
    let enter = 0;
    for (let i = 0; i < amount; i++) {
        if (exit === 0) {
            enter = Math.trunc(Math.random() * 100) + 1;
        } else {
            enter = exit
        }
        exit = Math.trunc(Math.random() * 100) + 1;
        if (enter < exit) {
            high = exit + Math.trunc(Math.random() * 20)
            low = enter - Math.trunc(Math.random() * 20)
        } else {
            low = exit - Math.trunc(Math.random() * 20)
            high = enter + Math.trunc(Math.random() * 20)
        }
        ret.push({
            low,
            high,
            exit,
            enter,
            candleId: new Date().getTime(),
        })
    }
    return ret
}

export function getFloatTransform(svg: any) {
    let curXGraph = 0;
    const currentTransfromGraph = svg.attr('transform');
    const regexpFloat = /-?\d+(\.\d+)?/;
    const match = regexpFloat.exec(currentTransfromGraph);
    if (match) {
        curXGraph = parseFloat(match[0])
    }
    return curXGraph;
}

export function drawAxisOnce(
    svg: any,
    y:any,
    xAxis: any,
    mm:number, md: number,
    margin: {
        left: number,
        top: number,
        bottom: number,
        right: number
    },
    height: number,
    widthSVG: number
){
    const yAxis = d3.axisRight(y)
            .tickValues(d3.range(mm, md, (md - mm) / 5))

        const axis = svg
            .selectAll(".x-axis")
            .data([0])
            .enter()
            .append('g')
            .attr('class', 'x-axis')
            .attr("transform", "translate(" + (-margin.left) + "," + (height ) + ")")
            .call(xAxis)

        axis
            .select('.domain')
            .attr('stroke-width', 0)
    
        axis
            .selectAll('.tick')
            .selectAll('line')
            .attr('color', '#262626')
            .attr('transform', 'translate(2,0)')
            .attr('stroke-width', `2`)
            .attr('y2', 0)
            .attr('y1', -height + margin.top)
            .attr('opacity', 1)

        axis
            .selectAll('.tick')
            .selectAll('text')
            .attr('opacity', 0)

        svg
            .selectAll(".y-axis")
            .data([0])
            .enter()
            .append('g')
            .attr('class', 'y-axis')

        const axisY =  svg
            .select(".y-axis")
            .call(yAxis)

        axisY
            .select('.domain')
            .attr('stroke-width', 0)
    
        axisY
            .selectAll('.tick')
            .selectAll('line')
            .attr('color', '#262626')
            .attr('stroke-width', `2`)
            .attr('x1', -widthSVG)
            .attr('x2', 2*widthSVG)
            .attr('opacity', 1)

        axisY
            .selectAll('.tick')
            .selectAll('text')
            .attr('opacity', 0)
}

export function drawCandles(
    svg: any, 
    candles: Candle[],
    x: any,
    y: any,
    pallette: {
        red: string,
        green: string,
    },
    isNew: boolean,
    isInit: boolean
) {
    svg
        .selectAll(".candle")
        .data(candles)
        .join(
            (enter: any) => { 
                const group = enter
                    .append('g')
                    .attr('class', 'candle')
                    .attr('id', (d: Candle) => d.candleId)
                group
                    .append('rect')
                    .attr('width', x.bandwidth())
                    .attr('height', (d: Candle) => {
                        return Math.abs(y(d.enter) - y(d.exit))
                    })
                    .attr('rx', "5")
                    .attr('ry', "5")
                    .attr('x', (d: Candle, i: number) => {
                        const res = x(i.toString());
                        return res ? res : 0;
                    })
                    .attr('y', (d: Candle) => d.enter > d.exit ? y(d.enter) : y(d.exit))
                    .attr('fill', (d: Candle) => d.enter > d.exit ? pallette.red: pallette.green)
                    
                let sel = group
                    .append('line')
                    .attr('class', 'candle-line')
                    .attr('stroke-width', `2`)
                    .attr('stroke', (d: Candle) => d.enter > d.exit? pallette.red: pallette.green)
                    .attr('x1', (d: Candle, i: number) => {
                        const res = x(i.toString());
                        return res ? res : 0;
                    })
                    .attr('x2', (d: Candle, i: number) => {
                        const res = x(i.toString());
                        return res ? res : 0;
                    })
                    .attr('y1', (d: Candle) => y(d.high))
                    .attr('y2', (d: Candle) => y(d.low))
                    .attr('transform', `translate(${x.bandwidth()/2}, 0)`)

                return sel
            },
            (update: any) => {
                update
                    .attr('id', (d: Candle)=> d.candleId)

                const res = update
                    .select('line')
                    .attr('x1', (d: Candle, i: number) => {
                        const res = x(i.toString());
                        return res ? res : 0;
                    })
                    .attr('x2', (d: Candle, i: number) => {
                        const res = x(i.toString());
                        return res ? res : 0;
                    })
                    .transition().duration(!isNew ? 200: 0)
                    .attr('transform', `translate(${x.bandwidth()/2}, 0)`)
                    .attr('y1', (d: Candle) => y(d.low))
                    .attr('y2', (d: Candle) => y(d.high))
                    .attr('stroke', (d: Candle) => d.enter > d.exit? pallette.red: pallette.green)

                update
                    .select('rect')
                    .attr('x', (d: Candle, i: number) => {
                        const res = x(i.toString());
                        return res ? res : 0;
                    })
                    .transition().duration(!isNew ? 200: 0)
                    .attr('width', x.bandwidth())
                    .attr('height', (d: Candle) => {
                        return Math.abs(y(d.enter) - y(d.exit))
                    })
                    .attr('y', (d: Candle) => d.enter > d.exit? y(d.enter) : y(d.exit))
                    .attr('fill', (d: Candle) => d.enter > d.exit? pallette.red: pallette.green)
                

                return res
            },
            (exit: any) => {
                return exit.remove()
            },
        )
}