import { combineReducers } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import candles, { NumberBubble, WebsocketUserInitData } from "./user/candles";
import { Candle } from "../components/Graph/types";
import modal, { ModalToShow } from "./modal/modal";
import click from "./click/click";
import leaderboard, { List } from "./leaderboard/leaderboard";
import friends, { Friend } from "./friends/friends";
import { Mood } from "../components/EnergyMenu/EnergyMenu";

export type RootReducer = {
    candles: {
        candles: Candle[],
        isNew: boolean,
        numbers: NumberBubble[],
        id: number,
        user: WebsocketUserInitData,
        durBalance: number,
        durEnergy: number,
        clicksAmount: number,
        mood: Mood,
        isCry: boolean
    },
    modal: {
        modalShown: ModalToShow
    },
    click: {
        click: boolean
    },
    leaderboard: {
        list: List
    },
    frindes: {
        friends: Friend[]
    }
}


const rootReducer = combineReducers({
    candles: candles,
    modal: modal,
    click: click,
    leaderboard: leaderboard,
    frindes: friends
})

export const store = configureStore( {reducer: rootReducer} )